<template>
  <div class="row d-flex justify-center align-center">
    <v-card class="tgc-content-card w-90 p-4">
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '@/assets/scss/md/_colors'

.w-90
    width: 90%

.tgc-content-card
    padding-top: 100px !important
</style>